<template>
	<div class="color-picker">
		<p class="label">
			Выберите цвет
		</p>
		<div :colorTxt="value" class="colors" :style="{ background: value }">
			<input type="color" :value="value" @input="setColor" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			default: "",
		},
		value: {
			default: "",
		},
	},
	methods: {
		setColor(e) {
			this.$emit("get", e.target.value);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

.colors {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	width: ptr(200px);
	height: ptr(50px);
	border-radius: ptr(8px);
	border: 1px solid $border-color;
	input {
		opacity: 0;
		width: ptr(200px);
		height: ptr(50px);
		border: unset;
		padding: 0;
	}
	&::after {
		content: attr(colorTxt);
		position: absolute;
		z-index: 2;
		color: white;
		mix-blend-mode: hard-light;
		pointer-events: none;
	}
}
.label {
	color: $text-color;
	margin: 0 0 ptr(10px);
	font-size: ptr(16px);
}
</style>
