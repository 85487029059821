var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"top-block"},[_c('h1',[_vm._v("Изменить контакты")]),_c('lang-selector',{on:{"get":function (e) { return (_vm.language = e); }}})],1),_c('div',{staticClass:"mass-container"},[_c('input-container',{ref:"description",attrs:{"options":{
				label: 'Описание',
				full: true,
				size: 3,
			},"value":_vm.whichVal('description')},on:{"get":function($event){return _vm.whichVal('description', $event)}}}),_c('input-container',{ref:"adress",attrs:{"options":{
				label: 'Адрес',
				size: 3,
			},"value":_vm.whichVal('address')},on:{"get":function($event){return _vm.whichVal('address', $event)}}}),_c('input-container',{ref:"email",attrs:{"options":{
				label: 'Email',
				size: 2,
			},"value":_vm.contactData.email},on:{"get":function($event){_vm.contactData.email = $event}}}),_c('input-container',{ref:"instagram",attrs:{"options":{
				label: 'Instagram',
			},"value":_vm.contactData.instagram},on:{"get":function($event){_vm.contactData.instagram = $event}}}),_c('input-container',{ref:"ogrn",attrs:{"options":{
				label: 'ОГРН',
				size: 1,
			},"value":_vm.contactData.ogrn},on:{"get":function($event){_vm.contactData.ogrn = $event}}}),_c('input-container',{ref:"inn",attrs:{"options":{
				label: 'ИНН',
				size: 1,
			},"value":_vm.contactData.inn},on:{"get":function($event){_vm.contactData.inn = $event}}}),_c('input-container',{ref:"kpp",attrs:{"options":{
				label: 'КПП',
				size: 1,
			},"value":_vm.contactData.kpp},on:{"get":function($event){_vm.contactData.kpp = $event}}}),_c('div',{staticClass:"blocks"},_vm._l((_vm.contactData.blocks),function(b,k){return _c('ContentBlock',{key:b.id + k,ref:"blocks",refInFor:true,attrs:{"data":_vm.dataBlocks[_vm.getCurrentScheme(b.id)],"language":_vm.language,"info":b,"outside":true},on:{"setData":function($event){return _vm.setBlocks($event, k)}}})}),1)],1),_c('button',{staticClass:"add",on:{"click":_vm.saveData}},[_vm._v(" Обновить данные ")])])}
var staticRenderFns = []

export { render, staticRenderFns }