<template>
	<div class="order-conteiner">
		<div @click="setOrder(order + 1)" class="arrow-container">
			<img src="/static/icons/arrow.svg" alt="" />
		</div>
		<input
			type="number"
			:value="order + 1"
			@blur="setOrder($event.target.value - 1)"
		/>
		<div @click="setOrder(order - 1)" class="arrow-container">
			<img src="/static/icons/arrow.svg" alt="" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		order: {
			default: 0,
		},
	},
	methods: {
		setOrder(n) {
			if (n !== this.order) {
				this.$emit("changeOrder", parseInt(n));
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

.order-conteiner {
	display: flex;
	border-radius: ptr(5px);
	border: 1px solid $border-color;
	width: fit-content;
	min-height: ptr(35px);
	margin-left: ptr(30px);
}
.arrow-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ptr(10px) ptr(10px);
	cursor: pointer;
	transition: $trs;
	img {
		width: ptr(12px);
		transition: $trs;
	}
	&:hover {
		background: $active-color;
		img {
			filter: brightness(0) invert(1);
		}
	}
}
.arrow-container:nth-child(3) {
	img {
		transform: rotate(180deg);
	}
}
input {
	padding: 0 ptr(10px);
	margin: 0;
	display: flex;
	background: none;
	justify-content: center;
	border-top: none;
	border-bottom: none;
	width: ptr(30px);
	color: $h-color;
	font-size: ptr(18px);
	text-align: center;
	font-family: $main-font;
	font-weight: 600;
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
</style>
