export default {
	methods: {
		getLangId(val) {
			return val.findIndex((e) => e.lang === this.language);
		},
		add(mass) {
			const obj = {};
			for (let f of mass) {
				if (f.type === "SubBlock") {
					if (f.name === "world" || f.name === "ru") {
						obj[f.name] = [];
					} else {
						obj[f.name] =
							f.kind === "Array"
								? [this.add(f.fields)]
								: this.add(f.fields);
					}
				} else if (f.type === "Image" || f.type === "File") {
					obj[f.name] = null;
				} else if (f.type === "Checkbox") {
					obj[f.name] = false;
				} else if (f.type === "Selector") {
					obj[f.name] = null;
				} else if (f.type === "Number") {
					obj[f.name] = 0;
				} else {
					if (f.kind === "Array") {
						obj[f.name] = [];
					} else {
						obj[f.name] = [
							{
								lang: "ru-RU",
								value: "",
							},
						];
					}
				}
			}
			return obj;
		},
		dataParse(str, back) {
			const checkZero = (d) => {
				return d > 9 ? d : "0" + d;
			};
			if (back) {
				let d = str.split(".");
				const tmz =
					new Date(d[2], d[1] - 1, d[0]).getTime() -
					new Date().getTimezoneOffset() * 60000;
				return new Date(tmz);
			}
			const d = new Date(str);
			return `${checkZero(d.getDate())}.${checkZero(
				d.getMonth() + 1
			)}.${d.getFullYear()}`;
		},
		setChekbox(name, set = null, subname, subId) {
			const val = this[this.objName];
			if (subId !== undefined) {
				val[name][subId][subname] = set;
			} else if (subname) {
				val[name][subname] = set;
			} else {
				val[name] = set;
			}
		},
		subToValue(name, subname, subId) {
			let val = this[this.objName][name];
			if (subId !== undefined) {
				val = val[subId];
			}
			if (subname) {
				val = val[subname];
			}
			return val;
		},
		whichVal(name, set = null, subname, subId) {
			const val = this.subToValue(name, subname, subId);
			const id = this.getLangId(val);
			if (set) {
				val[id].value = set;
				return;
			}
			return val[id].value;
		},
		whichImages(name, imgs, get = false) {
			const id = this.getLangId(this[this.objName][name]);
			if (get) {
				const isOne = imgs.length === undefined;
				if (id === -1) {
					if (isOne) {
						this[this.objName][name].push({
							id: imgs.id,
							lang: this.language,
						});
					} else {
						for (let i in imgs) {
							this[this.objName][name].push(i.id);
						}
					}
				} else {
					this[this.objName][name][id].id = isOne
						? imgs.id
						: imgs.map((e) => e.id);
				}
				return;
			}
			if (id >= 0) return this[this.objName][name][id];
			else return null;
		},
	},
};
