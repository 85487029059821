<template>
	<section
		@mousemove="moveEvent"
		@mouseup="ungrabEvent"
		@mouseleave="ungrabEvent"
	>
		<div class="top-block">
			<h1>TimeLife</h1>
			<button @click="$router.push('/TimeLine-event/')">
				Добавить событие
			</button>
		</div>
		<div class="legend">
			<span></span>
			<div class="column">
				Название
				<img src="" alt="" />
			</div>
			<div class="column changed-date">
				Изменено
				<img src="" alt="" />
			</div>
		</div>
		<div class="table main-events" :class="{ moving: movingTarget.el }">
			<div
				v-for="(e, k) in mainEvents"
				:key="k"
				class="move-component draggable"
			>
				<div class="artist-name">
					{{ e.title }}
				</div>
				<div class="changed-date">
					{{ parseDate(e.updatedAt) }}
				</div>
				<div tabindex="0" class="options">
					<div class="settings-component">
						<router-link
							:to="'/TimeLine-event/' + e.id"
							class="opt"
						>
							Редактировать
						</router-link>
						<div @click="deleteEvent(e.id)" class="opt">
							Удалить
						</div>
					</div>
					<img src="/static/icons/kebab-menu.svg" alt="" />
				</div>
			</div>
			<div class="pages-select">
				<div
					v-for="(p, k) in pagesNum"
					:key="k"
					class="page"
					:class="{ selected: p === selectedPage }"
					@click="selectedPage = p"
				>
					{{ p }}
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import dragMixin from "@/views/mixins/dragMixin";
import { http } from "@/components/AuthService/httpService";
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";
export default {
	mixins: [dragMixin, inputHandlerMixin],
	data() {
		return {
			loading: false,
			mainEvents: [],
			pagesNum: 1,
			selectedPage: 1,
		};
	},
	methods: {
		getAllEvents() {
			http.get(`/api/timelife?page=${this.selectedPage}`).then(
				(response) => {
					if (response.status) {
						this.mainEvents = response.data.items;
						this.pagesNum = response.data.totalPages;
					}
				}
			);
		},
		deleteEvent(id) {
			http.delete("/api/timelife/" + id).then((response) => {
				if (response.status < 400) {
					this.getAllEvents();
				}
			});
		},
		changeStatus(id, hide) {
			http.put(`/api/timelife/${id}/${hide ? "hide" : "publish"}`).then(
				(response) => {
					if (response.status < 400) {
						this.getAllEvents();
					}
				}
			);
		},
		saveSort(name) {
			http.put("/api/events/sort", {
				entries: this[name],
			}).then((response) => {
				if (response.status < 400) {
					console.log(response);
				}
			});
		},
		changeType(id, bool = true) {
			http.put(`/api/events/${id}/${bool ? "main" : "ordinary"}`).then(
				(response) => {
					if (response.status < 400) {
						this.getAllEvents();
					}
				}
			);
		},
	},
	mounted() {
		this.getAllEvents();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/defaultPage.scss";

.artist-name {
	grid-column: 2 / 4;
}

.changed-date {
	grid-column: 4;
}

.options {
	grid-column: 5;
}

.pages-select {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: min-content;
	align-items: center;
	gap: ptr(10px);
	margin-left: ptr(50px);
	margin-top: ptr(20px);
}

.page {
	display: flex;
	align-items: center;
	justify-content: center;
	width: ptr(30px);
	height: ptr(30px);
	border-radius: ptr(5px);
	color: $text-color;
	border: 1px solid $border-color;
	transition: $trs;
	cursor: pointer;
}

.selected {
	background: $active-color;
	border: 1px solid $active-color;
	color: white;
}
</style>
