<template>
	<div class="block-selector">
		<div class="label-info">Блоки</div>
		<div
			class="tags-container"
			tabindex="0"
			@click="openCards = true"
			@blur="openCards = false"
		>
			Выбрать блок
			<img src="/static/icons/arrow.svg" alt="" />
			<div v-if="openCards" class="options-container">
				<div
					v-for="(e, k) in data"
					:key="k"
					@click="$emit('add', e)"
					class="opt"
				>
					{{ e.label }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			default: () => [],
		},
	},
	data() {
		return {
			openCards: false,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";
.block-selector {
	display: grid;
	gap: ptr(10px);
	width: 100%;
	position: relative;
}
.options-container {
	position: absolute;
	z-index: 2;
	top: 100%;
	left: 0;
	width: calc(100% - 2rem / 16);
	border-radius: 0 0 $brd $brd;
	background: white;
	border-top: 1px solid transparent;
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
}
.tags-container {
	border: 1px solid $border-color;
	border-radius: $brd;
	width: 100%;
	box-sizing: border-box;
	height: ptr(45px);
	padding: 0 ptr(10px);
	display: flex;
	align-items: center;
	transition: $trs;
	background: white;
	&:focus-within {
		border: 1px solid $active-color;
	}
	img {
		position: absolute;
		width: ptr(15px);
		right: ptr(15px);
		opacity: 0.5;
	}
	input {
		background: none;
		border: none;
		height: 100%;
		width: 100%;
		font-size: ptr(14px);
		font-weight: 500;
		font-family: $main-font;
		color: black;
		&:focus {
			outline: none;
		}
	}
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	span {
		margin-left: ptr(5px);
		color: $red;
	}
}
.opt {
	padding: ptr(10px) ptr(20px);
	font-weight: 500;
	font-size: ptr(16px);
	cursor: pointer;
	transition: $trs;
	&:hover {
		background: $active-color;
		color: white;
	}
}
</style>
