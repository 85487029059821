<template>
	<div class="tags-component">
		<div class="label-info">{{ localedOptions.label }}<span>*</span></div>
		<div class="tags-container">
			<div v-for="(t, k) in value" :key="k" class="tag">
				{{ t[0].value }}
				<img
					src="/static/icons/close.svg"
					alt=""
					@click="deleteInput(k)"
				/>
			</div>
			<input
				v-model="inp"
				placeholder="Написать..."
				@keydown.enter="setInput"
				@keydown.delete="
					inp.length === 0 ? deleteInput(value.length - 1) : null
				"
				type="text"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		options: {
			default: () => ({}),
		},
		value: {
			default: () => [],
		},
	},
	data() {
		return {
			errorText: "",
			inp: "",
			localedOptions: {
				label: "",
				required: true,
				placeholder: "",
				full: false,
				size: 2,
				textarea: false,
			},
		};
	},
	methods: {
		checkReq(errComponent) {
			if (!this.localedOptions.required || !errComponent) return false;
			this.error = true;
			if (errComponent) {
				this.errorText = errComponent.txt;
			} else {
				this.errorText = "Вы не указали данные";
			}
			return true;
		},
		setInput() {
			const val = [
				{
					lang: "ru-RU",
					value: this.inp,
				},
			];
			this.inp = "";
			this.saveInfo([...this.value, val]);
		},
		deleteInput(k) {
			if (this.value.length === 0) {
				return;
			}
			const val = [...this.value];
			val.splice(k, 1);
			this.saveInfo(val);
		},
		saveInfo(value) {
			this.$emit("get", value);
		},
	},
	watch: {
		error(newVal) {
			if (!newVal) {
				this.errorText = "";
			}
		},
	},
	mounted() {
		new Promise(() => {
			Object.assign(this.localedOptions, this.options);
		});
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

.tags-component {
	width: ptr(700px);
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	margin-bottom: ptr(10px);
	span {
		margin-left: ptr(5px);
		color: $red;
		position: relative;
	}
	div {
		position: absolute;
		left: ptr(20px);
		top: 0;
		width: ptr(230px);
		background: white;
		border: 1px solid $red;
		border-radius: ptr(5px);
		padding: ptr(10px);
		pointer-events: none;
	}
}
.tags-container {
	border: 1px solid $border-color;
	border-radius: $brd;
	width: 100%;
	box-sizing: border-box;
	min-height: ptr(45px);
	padding: ptr(10px) ptr(10px) 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	transition: $trs;
	background: white;
	&:focus-within {
		border: 1px solid $active-color;
	}
	input {
		background: none;
		border: none;
		height: 100%;
		width: fit-content;
		font-size: ptr(14px);
		font-weight: 500;
		font-family: $main-font;
		margin-bottom: ptr(10px);
		color: black;
		&:focus {
			outline: none;
		}
	}
}
.tag {
	padding: ptr(4px) ptr(10px) ptr(4px) ptr(10px);
	background: #68c9ff;
	border-radius: $brd;
	color: white;
	display: flex;
	font-size: ptr(14px);
	margin: 0 ptr(10px) ptr(10px) 0;
	white-space: nowrap;
	img {
		margin-left: ptr(15px);
		width: ptr(7.5px);
		cursor: pointer;
	}
}
</style>
