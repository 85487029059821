<template>
	<div class="container">
		<template v-if="isArray">
			<template v-for="(s, i) in info.data[data.name]">
				<ContentBlock
					:ref="data.name"
					:key="data.type + i"
					:data="data"
					:language="language"
					:info="{ data: s, sortOrder: i }"
					:subBlock="true"
					:multiplyLength="info.data[data.name].length"
					:position="i"
					@sort="sortBlocks"
					@setData="setBlocks({ data: $event, key: i })"
					@addBlock="$emit('addBlock')"
					@deleteBlock="$emit('deleteBlock', i)"
				/>
			</template>
		</template>
		<!-- @setData="$emit('setBlocks', { data: $event, key: i })" -->
		<template v-else>
			<ContentBlock
				:ref="data.name"
				:data="data"
				:language="language"
				:info="{ data: info.data[data.name] }"
				:subBlock="true"
				@setData="setBlocks({ data: $event })"
			/>
		</template>
	</div>
</template>

<script>
export default {
	name: "multiple-block",
	props: {
		info: {
			default: () => ({}),
		},
		data: {
			default: () => ({}),
		},
		language: {
			default: "ru-RU",
		},
	},
	methods: {
		checkReq(errComponent) {
			let chk = false;
			for (let k in this.$refs) {
				chk = this.isArray
					? this.$refs[k].forEach((e) => e.checkReq(errComponent))
					: this.$refs[k].checkReq(errComponent);
			}
			return chk;
		},
		setBlocks(event) {
			let filteredArray;
			if (this.isArray) {
				filteredArray = [...this.info.data[this.data.name]];
				filteredArray.splice(event.key, 1, event.data);
			} else {
				filteredArray = event.data;
			}
			this.$emit("setBlocks", filteredArray);
		},
		sortBlocks(lastPos, nextPos) {
			const copyInfo = [...this.info.data[this.data.name]];
			copyInfo.forEach((e, k) => (e.sortOrder = k));
			copyInfo[nextPos].sortOrder = lastPos;
			copyInfo[lastPos].sortOrder = nextPos;
			this.$emit(
				"setBlocks",
				copyInfo.sort((a, b) => {
					return a.sortOrder - b.sortOrder;
				})
			);
		},
	},

	computed: {
		isArray() {
			return this.data.kind === "Array";
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../components/styles/config.scss";
.container {
	display: grid;
	gap: ptr(50px);
	width: ptr(700px);
}
</style>
