export default {
	data() {
		return {
			movingTarget: {
				obj: null,
				massName: "",
				el: null,
			},
			movingPos: {
				y: 0,
				lastY: 0,
				directionUp: false,
			},
		};
	},
	methods: {
		moveEvent(e) {
			const t = this.movingTarget;
			if (t.el) {
				const y = this.movingPos.y - e.clientY;
				t.el.style.transform = `translate3d(0,${-y}px,0)`;
				this.movingPos.directionUp =
					this.movingPos.lastY - y >= 0 ? false : true;
				this.movingPos.lastY = y;
			}
		},
		moveOtherEvent(e, k, name) {
			if (this.movingTarget.el === null) {
				return;
			}
			const component = e.target.closest(".draggable");
			const height = component.offsetHeight;
			if (this.movingTarget.el && this.movingTarget.massName === name) {
				if (
					this.movingPos.directionUp &&
					component.style.transform === ""
				) {
					component.style.transform = `translate3d(0,${height}px,0)`;
				} else if (
					!this.movingPos.directionUp &&
					component.style.transform === ""
				) {
					component.style.transform = `translate3d(0,${-height}px,0)`;
				} else {
					component.style.transform = "";
				}
				const sw = this.movingTarget.obj.sortOrder;
				this.movingTarget.obj.sortOrder = this[name][k].sortOrder;
				this[name][k].sortOrder = sw;
			}
		},
		grabEvent(e, k, name) {
			this.movingTarget = {
				obj: this[name][k],
				massName: name,
				el: e.target.closest(".draggable"),
			};
			this.movingTarget.el.classList.add("grab");
			this.movingPos = {
				y: e.clientY,
				lastY: e.clientY,
				directionUp: false,
			};
		},
		ungrabEvent() {
			if (this.movingTarget.el === null) {
				return;
			}
			this.sortEvents(this[this.movingTarget.massName]);
			this.movingTarget.el.classList.remove("grab");
			for (let ch of this.movingTarget.el.closest(".table").children) {
				ch.style = "";
			}
			this.saveSort(this.movingTarget.massName);
			this.movingTarget = {
				obj: null,
				massName: null,
				el: null,
			};
			this.movingPos = {
				y: 0,
				lastY: 0,
				directionUp: true,
			};
		},
		parseDate(date) {
			const checkZero = (e) => {
				return e > 9 ? e : "0" + e;
			};
			let d = new Date(date);
			d = new Date(
				new Date(d).getTime() - new Date().getTimezoneOffset() * 60000
			);
			const y = d.getFullYear();
			return `${checkZero(d.getDate())}.${checkZero(
				d.getMonth() + 1
			)}.${y - 2000} ${checkZero(d.getHours())}:${checkZero(
				d.getMinutes()
			)}`;
		},
		sortEvents(mass) {
			mass.sort((a, b) => {
				return a.sortOrder - b.sortOrder;
			});
		},
	},
};
