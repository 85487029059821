<template>
	<div class="form-container">
		<div class="map-select">
			<div
				class="slc"
				:class="{ selected: selectedType === 'world' }"
				@click="selectedType = 'world'"
			>
				World
			</div>
			<div
				class="slc"
				:class="{ selected: selectedType === 'ru' }"
				@click="selectedType = 'ru'"
			>
				Russia
			</div>
		</div>
		<custom-checkbox
			:start="data.showRussiaFirst"
			label="Показывать Россию первой"
			@get="setFirstMap($event)"
		/>
		<div
			class="map-container"
			:class="{ ru: selectedType === 'ru' }"
			@click="setPoint"
		>
			<img
				:src="
					`/static/img/${
						selectedType === 'ru' ? 'map-ru' : 'map'
					}.svg`
				"
				alt=""
				class="map-image"
			/>
			<div
				class="dot-el"
				v-for="(d, k) in data[selectedType]"
				:key="k"
				:num="k + 1"
				:style="`left: ${d.x}rem; top: ${d.y}rem;`"
			>
				<img src="/static/icons/point.svg" alt="" />
			</div>
		</div>
		<div class="dots-container">
			<div
				v-for="(d, k) in data[selectedType]"
				:key="k"
				class="dots-info"
			>
				<div class="txt-container">
					<h3>Точка {{ k + 1 }}</h3>
					<button @click="deletePoint(k)" class="delete">
						Удалить
					</button>
				</div>
				<input-container
					ref="city"
					:value="whichVal(selectedType, null, 'city', k)"
					:options="{
						label: 'Город',
						required: true,
					}"
					@get="getValue($event, 'city', k)"
				/>
				<input-container
					ref="address"
					:value="whichVal(selectedType, null, 'address', k)"
					:options="{
						label: 'Адрес',
						textarea: true,
						size: 2,
						required: true,
					}"
					@get="getValue($event, 'address', k)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";
import InputContainer from "./InputContainer.vue";
import CustomCheckbox from "./CustomCheckbox.vue";
export default {
	components: { InputContainer, CustomCheckbox },
	mixins: [inputHandlerMixin],
	props: {
		language: {
			default: "ru-RU",
		},
		data: {
			default: () => [],
		},
	},
	data() {
		return {
			objName: "data",
			selectedType: "world",
		};
	},
	methods: {
		setPoint(e) {
			const rSize = parseFloat(
				window.getComputedStyle(document.documentElement).fontSize
			);
			const x = e.offsetX / rSize;
			const y = e.offsetY / rSize;
			this.setInfo(
				{
					city: [
						{
							lang: "ru-RU",
							value: "",
						},
					],
					address: [
						{
							lang: "ru-RU",
							value: "",
						},
					],
					x,
					y,
				},
				this.data[this.selectedType].length
			);
		},
		deletePoint(k) {
			const mass = [...this.data[this.selectedType]];
			mass.splice(k, 1);
			this.$emit("get", { ...this.data, [this.selectedType]: mass });
		},
		setInfo(point, k) {
			let mass = [...this.data[this.selectedType]];
			mass[k] = point;
			this.$emit("get", { ...this.data, [this.selectedType]: mass });
		},
		setFirstMap(value) {
			this.$emit("get", { ...this.data, showRussiaFirst: value });
		},
		getValue(value, name, k) {
			const el = this.data[this.selectedType][k];
			const fEl = el[name].filter((e) => e.lang !== this.language);
			this.setInfo(
				{
					...el,
					[name]: [...fEl, { lang: this.language, value }],
				},
				k
			);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

.form-container {
	display: grid;
	gap: ptr(20px);
}

.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	span {
		margin-left: ptr(5px);
		color: $red;
		position: relative;
	}
}
.map-select {
	display: flex;
	border: 1px solid $border-color;
	border-radius: $brd;
	width: fit-content;
}
.slc {
	padding: ptr(5px) ptr(10px);
	font-size: ptr(20px);
	cursor: pointer;
	transition: $trs;
}
.selected {
	color: white;
	background: $active-color;
}
.map-container {
	border: 1px solid $active-color;
	border-radius: $brd;
	position: relative;
	overflow: hidden;
	width: ptr(1391px);
}
.map-image {
	width: ptr(1391px);
	user-select: none;
	pointer-events: none;
}
.ru {
	width: ptr(1210px);
	.map-image {
		width: ptr(1210px);
	}
}
.dot-el {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	margin: ptr(-58px) 0 0 ptr(-19px);
	img {
		width: ptr(39px);
	}
	&::before {
		content: attr(num);
		position: absolute;
		left: ptr(15px);
		top: ptr(9px);
		font-size: ptr(16px);
		font-weight: 600;
	}
}
.dots-info {
	display: grid;
	gap: ptr(10px);
}
.txt-container {
	display: flex;
	margin: ptr(20px) 0 0;
	align-items: center;
	h3 {
		font-weight: 500;
		margin: 0;
		font-size: ptr(30px);
	}
}
.delete {
	margin-left: ptr(20px);
}
.dots-container {
	display: grid;
	grid-template-columns: repeat(3, min-content);
	gap: ptr(40px);
}
</style>
