import Vue from "vue";
import VueRouter from "vue-router";
import Events from "../views/Events.vue";
import ProjectPage from '@/views/ProjectPage';
import EnterPage from '@/views/EnterPage';
import TimeLine from '@/views/TimeLine';
import TimeLineEvent from '@/views/TimeLineEvent';
import Contacts from '@/views/Contacts';
import OtherPages from '@/views/OtherPages';

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Events",
		component: Events,
	},
	{
		path: "/auth",
		name: "Authitication",
		component: EnterPage,
	},
	{
		path: "/project/:id?",
		name: "project",
		component: ProjectPage,
	},
	{
		path: "/TimeLine",
		name: "TimeLine",
		component: TimeLine,
	},
	{
		path: "/TimeLine-event/:id?",
		name: "TimeLine-event",
		component: TimeLineEvent,
	},
	{
		path: "/contacts",
		name: "Contacts",
		component: Contacts,
	},
	{
		path: "/pages",
		name: "OtherPages",
		component: OtherPages,
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
