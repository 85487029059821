<template>
	<section
		@mousemove="moveEvent"
		@mouseup="ungrabEvent"
		@mouseleave="ungrabEvent"
	>
		<div class="top-block">
			<h1>Проекты</h1>
			<button @click="$router.push('/project')">Добавить проект</button>
		</div>
		<div class="add-new-group">
			<input-container
				:options="{
					label: 'Название группы',
					placeholder: 'Введите...',
				}"
				:value="newGroupName[0].value"
				@get="(e) => (newGroupName[0].value = e)"
			/>
			<button @click="createNewGroup" class="add">
				Создать новую группу
			</button>
		</div>
		<div class="legend">
			<span></span>
			<div class="column">
				Название
				<img src="" alt="" />
			</div>
			<div class="column">
				Статус
				<img src="" alt="" />
			</div>
			<div class="column">
				Изменено
				<img src="" alt="" />
			</div>
		</div>
		<div class="table main-events" :class="{ moving: movingTarget.el }">
			<div
				v-for="(g, k) in groupProjects"
				:key="g.id"
				class="draggable group-container"
			>
				<div class="group-name">
					<div class="arrows">
						<img
							v-if="k > 0"
							@click="changePosition(k, -1)"
							class="top"
							src="/static/icons/right-arrow.svg"
							alt=""
						/>
						<img
							v-if="k < groupProjects.length - 1"
							@click="changePosition(k, 1)"
							class="bottom"
							src="/static/icons/right-arrow.svg"
							alt=""
						/>
					</div>
					<input v-if="g.changeMode" v-model="g.name" type="text" />
					<p v-else>
						{{ g.name }}
					</p>
					<img
						v-if="!g.changeMode"
						@click="g.changeMode = true"
						class="rewrite"
						src="/static/icons/writing.svg"
						alt=""
					/>
					<div v-if="g.changeMode" class="buttons">
						<button @click="deleteGroup(g.id)" class="delete">
							Удалить
						</button>
						<button @click="saveGroupChanges(g.id, g)" class="add">
							Сохранить
						</button>
					</div>
				</div>
				<group-projects-container :group="g" :groups="groups">
				</group-projects-container>
			</div>
		</div>
	</section>
</template>

<script>
import dragMixin from "@/views/mixins/dragMixin";
import { http } from "@/components/AuthService/httpService";
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";
import InputContainer from "./components/InputContainer.vue";
import GroupProjectsContainer from "../components/GroupProjectsContainer.vue";
export default {
	components: { InputContainer, GroupProjectsContainer },
	mixins: [dragMixin, inputHandlerMixin],
	data() {
		return {
			loading: false,
			newGroupName: [
				{
					lang: "ru-RU",
					value: "",
				},
			],
			allMoveComponents: [],
			groups: [],
			groupProjects: [],
		};
	},
	methods: {
		getAllProjects() {
			http.get("/api/projects").then((response) => {
				if (response.status) {
					this.groupProjects = response.data.groups.map((g, i) => ({
						...g,
						sortOrder: i,
						changeMode: false,
						projects: g.projects.map((e, k) => ({
							...e,
							sortOrder: k,
						})),
					}));
					this.allMoveComponents = response.data.projects.map(
						(e, k) => ({
							...e,
							sortOrder: k,
						})
					);
				}
			});
		},
		createNewGroup() {
			http.post("/api/project-groups", {
				name: this.newGroupName,
			}).then((response) => {
				if (response.status < 400) {
					this.newGroupName = [
						{
							lang: "ru-RU",
							value: "",
						},
					];
				}
			});
		},
		changePosition(pos, dir) {
			const buff = this.groupProjects[pos].sortOrder;
			this.groupProjects[pos].sortOrder = this.groupProjects[
				pos + dir
			].sortOrder;
			this.groupProjects[pos + dir].sortOrder = buff;
			this.sortEvents(this.groupProjects);
			this.saveSort();
		},
		saveSort() {
			http.put(`/api/project-groups/sort`, {
				items: this.groupProjects,
			}).then((response) => {
				if (response.status < 400) {
					this.$emit("getAllProjects");
				}
			});
		},
		getGroups() {
			http.get("/api/project-groups").then((response) => {
				if (response.status < 400) {
					this.groups = response.data.groups;
				}
			});
		},
		deleteGroup(id) {
			http.delete(`/api/project-groups/${id}`).then((response) => {
				if (response.status < 400) {
					this.$emit("getAllProjects");
				}
			});
		},
		saveGroupChanges(id, group) {
			http.put(`/api/project-groups/${id}`, {
				name: [{ lang: "ru-RU", value: group.name }],
			}).then((response) => {
				if (response.status < 400) {
					this.$emit("getAllProjects");
				}
			});
		},
	},
	mounted() {
		this.getGroups();
		this.getAllProjects();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/defaultPage.scss";

.add-new-group {
	display: grid;
	gap: ptr(30px);
	padding: ptr(40px) ptr(56px);
	border-radius: ptr(20px);
	border: 1px dashed $active-color;
}

.group-name {
	display: grid;
	align-items: center;
	grid-template-columns: ptr(40px) max-content max-content auto;
	background: $back-color;
	gap: ptr(8px);
	padding-left: ptr(10px);
	font-size: ptr(24px);
	font-weight: 700;
	margin-top: ptr(30px);

	input {
		color: $h-color;
		font-weight: 700;
		font-size: ptr(24px);
		border: none;
		border-bottom: 1px solid $active-color;
		padding: ptr(10px) 0;
		margin: ptr(10px) 0;
	}
	p {
		color: $h-color;
		margin: 0;
		padding: ptr(20px) 0;
		margin-right: ptr(20px);
	}
}

.arrows {
	display: grid;
	align-items: center;
	gap: ptr(3px);
	img {
		width: ptr(15px);
		height: ptr(15px);
		cursor: pointer;
		object-fit: contain;
	}
}
.top {
	transform: rotate(-90deg);
}
.bottom {
	transform: rotate(90deg);
}
.rewrite {
	width: ptr(20px);
	height: ptr(20px);
	cursor: pointer;
	object-fit: contain;
}

.buttons {
	display: grid;
	gap: ptr(20px);
	justify-content: flex-end;
	grid-template-columns: repeat(2, max-content);
	grid-column: 4;
	button {
		margin: 0;
	}
}
</style>
