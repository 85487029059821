var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('div',{staticClass:"map-select"},[_c('div',{staticClass:"slc",class:{ selected: _vm.selectedType === 'world' },on:{"click":function($event){_vm.selectedType = 'world'}}},[_vm._v(" World ")]),_c('div',{staticClass:"slc",class:{ selected: _vm.selectedType === 'ru' },on:{"click":function($event){_vm.selectedType = 'ru'}}},[_vm._v(" Russia ")])]),_c('custom-checkbox',{attrs:{"start":_vm.data.showRussiaFirst,"label":"Показывать Россию первой"},on:{"get":function($event){return _vm.setFirstMap($event)}}}),_c('div',{staticClass:"map-container",class:{ ru: _vm.selectedType === 'ru' },on:{"click":_vm.setPoint}},[_c('img',{staticClass:"map-image",attrs:{"src":("/static/img/" + (_vm.selectedType === 'ru' ? 'map-ru' : 'map') + ".svg"),"alt":""}}),_vm._l((_vm.data[_vm.selectedType]),function(d,k){return _c('div',{key:k,staticClass:"dot-el",style:(("left: " + (d.x) + "rem; top: " + (d.y) + "rem;")),attrs:{"num":k + 1}},[_c('img',{attrs:{"src":"/static/icons/point.svg","alt":""}})])})],2),_c('div',{staticClass:"dots-container"},_vm._l((_vm.data[_vm.selectedType]),function(d,k){return _c('div',{key:k,staticClass:"dots-info"},[_c('div',{staticClass:"txt-container"},[_c('h3',[_vm._v("Точка "+_vm._s(k + 1))]),_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.deletePoint(k)}}},[_vm._v(" Удалить ")])]),_c('input-container',{ref:"city",refInFor:true,attrs:{"value":_vm.whichVal(_vm.selectedType, null, 'city', k),"options":{
					label: 'Город',
					required: true,
				}},on:{"get":function($event){return _vm.getValue($event, 'city', k)}}}),_c('input-container',{ref:"address",refInFor:true,attrs:{"value":_vm.whichVal(_vm.selectedType, null, 'address', k),"options":{
					label: 'Адрес',
					textarea: true,
					size: 2,
					required: true,
				}},on:{"get":function($event){return _vm.getValue($event, 'address', k)}}})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }