var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"top-block"},[_c('h1',[_vm._v(_vm._s(_vm.id ? "Изменить новость" : "Добавить новость"))]),_c('lang-selector',{on:{"get":function (e) { return (_vm.language = e); }}})],1),_c('article',[_c('input-container',{ref:"title",attrs:{"options":{
				label: 'Название',
			},"value":_vm.whichVal('title')},on:{"get":function($event){return _vm.whichVal('title', $event)}}}),_c('input-container',{ref:"date",attrs:{"value":_vm.news.date,"options":{
				label: 'Дата',
				type: 'Date',
			}},on:{"get":function($event){_vm.news.date = $event}}}),_c('photo-loader',{attrs:{"options":{
				label: 'Изображение',
				onlyOne: true,
			},"images":_vm.news.image},on:{"photoId":function($event){_vm.news.image = $event.id}}}),_c('input-container',{ref:"content",attrs:{"label":"Описание","value":_vm.whichVal('content'),"options":{
				label: 'Описание',
				type: 'Textarea',
			}},on:{"get":function($event){return _vm.whichVal('content', $event)}}}),_c('custom-selector',{attrs:{"options":{
				label: 'Проект',
				inValue: false,
				data: _vm.allProjects,
			},"selectedValue":_vm.news.projectId},on:{"get":function($event){_vm.news.projectId = $event}}})],1),_c('button',{staticClass:"add",on:{"click":function($event){return _vm.saveData(_vm.id ? 'put' : 'post')}}},[_vm._v(" "+_vm._s(_vm.id ? "Изменить" : "Добавить")+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }