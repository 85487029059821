var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"static-content"},[_c('div',{staticClass:"top-block"},[_c('h1',[_vm._v(_vm._s(_vm.id ? "Изменить проект" : "Добавить проект"))]),_c('lang-selector',{on:{"get":function (e) { return (_vm.language = e); }}})],1),_c('input-container',{ref:"name",attrs:{"options":{
				label: 'Название',
			},"value":_vm.whichVal('name')},on:{"get":function($event){return _vm.whichVal('name', $event)}}}),_c('custom-selector',{attrs:{"options":{ label: 'Группа', size: 2, data: _vm.allGroups }},on:{"get":function($event){_vm.projectData.groupId = $event.id}}}),_c('input-container',{ref:"description",attrs:{"value":_vm.whichVal('description'),"options":{
				label: 'Описание',
				type: 'Textarea',
			}},on:{"get":function($event){return _vm.whichVal('description', $event)}}}),_c('input-container',{ref:"type",attrs:{"label":"Тип","value":_vm.whichVal('type'),"options":{
				label: 'Тип',
			}},on:{"get":function($event){return _vm.whichVal('type', $event)}}}),_c('input-container',{ref:"email",attrs:{"value":_vm.projectData.email,"options":{
				label: 'Email',
			}},on:{"get":function($event){_vm.projectData.email = $event}}}),_c('input-container',{ref:"facebook",attrs:{"value":_vm.projectData.socials.facebook,"options":{
				label: 'Facebook',
			}},on:{"get":function($event){_vm.projectData.socials.facebook = $event}}}),_c('input-container',{ref:"email",attrs:{"value":_vm.projectData.socials.instagram,"options":{
				label: 'Instagram',
			}},on:{"get":function($event){_vm.projectData.socials.instagram = $event}}}),_c('input-container',{ref:"email",attrs:{"value":_vm.projectData.socials.vk,"options":{
				label: 'Vk',
			}},on:{"get":function($event){_vm.projectData.socials.vk = $event}}}),_c('input-container',{ref:"email",attrs:{"value":_vm.projectData.socials.telegram,"options":{
				label: 'Telegram',
			}},on:{"get":function($event){_vm.projectData.socials.telegram = $event}}}),_c('input-container',{ref:"url",attrs:{"value":_vm.projectData.url,"options":{
				label: 'Ссылка',
			}},on:{"get":function($event){_vm.projectData.url = $event}}}),_c('input-container',{ref:"siteUrl",attrs:{"value":_vm.projectData.siteUrl,"options":{
				label: 'Ссылка на проект',
			}},on:{"get":function($event){_vm.projectData.siteUrl = $event}}}),_c('photo-loader',{ref:"logo",attrs:{"options":{
				label: 'Логотип',
				onlyOne: true,
			},"images":_vm.projectData.logo},on:{"photoId":function($event){_vm.projectData.logo = $event}}}),_c('photo-loader',{ref:"banner",attrs:{"options":{
				label: 'Баннер',
				onlyOne: true,
				required: true,
				type: 'File',
			},"images":_vm.projectData.banner},on:{"photoId":function($event){_vm.projectData.banner = $event}}}),_c('custom-checkbox',{attrs:{"label":"В процессе","start":_vm.projectData.inProgress},on:{"get":function($event){return _vm.setChekbox('inProgress', $event)}}})],1),_c('button',{staticClass:"top-fx add",on:{"click":function($event){return _vm.saveData(_vm.id ? 'put' : 'post')}}},[_vm._v(" "+_vm._s(_vm.id ? "Обновить данные" : "Добавить")+" ")]),(_vm.dataBlocks.length > 0)?_c('div',{staticClass:"blocks-content"},[_c('p',[_vm._v("Блоки:")]),_c('ul',_vm._l((_vm.sideBlocks),function(d,k){return _c('li',{key:k,on:{"click":function($event){return _vm.goToBlock(d.id)}}},[_vm._v(" "+_vm._s(k + 1)+". "+_vm._s(d.label)+" ")])}),0)]):_vm._e(),_vm._l((_vm.dataInfo),function(b,k){return _c('ContentBlock',{key:b.id + k,ref:"blocks",refInFor:true,attrs:{"id":("block-number-" + k),"data":_vm.dataBlocks[_vm.getCurrentScheme(b.id)],"language":_vm.language,"info":b},on:{"sort":_vm.sortBlocks,"setData":function($event){return _vm.setBlocks($event, k)},"deleteBlock":function($event){return _vm.deleteBlock(k)}}})}),_c('article',[_c('block-selector',{attrs:{"data":_vm.allBlocks},on:{"add":_vm.addBlock}})],1),_c('button',{staticClass:"add",on:{"click":function($event){return _vm.saveData(_vm.id ? 'put' : 'post')}}},[_vm._v(" "+_vm._s(_vm.id ? "Обновить данные" : "Добавить")+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }