var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:{ 'sub-block': _vm.subBlock, outside: _vm.outside }},[_c('div',{staticClass:"block-head-info-container"},[_c('h2',[_vm._v(" "+_vm._s(_vm.data.label)+" ")]),((_vm.data.kind === 'Array' || !_vm.subBlock) && !_vm.outside)?_c('order-block',{attrs:{"order":_vm.info.sortOrder},on:{"changeOrder":_vm.sortByOrder}}):_vm._e()],1),(_vm.data.name === 'map')?_c('map-component',{attrs:{"data":_vm.info.data},on:{"get":function($event){return _vm.$emit('setData', {
				data: $event,
				id: _vm.info.id,
				sortOrder: _vm.info.sortOrder,
			})}}}):_c('div',{staticClass:"mass-container"},[_vm._l((_vm.data.fields),function(d,k){return [(d.type === 'Checkbox')?_c('custom-checkbox',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"label":d.label,"start":_vm.info.data[d.name]},on:{"get":function($event){return _vm.setChekbox('data', $event, d.name)}}}):(d.type === 'Select')?_c('custom-selector',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"options":{
					inValue: true,
					label: d.label,
					data: d.params.values,
					requared: d.validation.required,
				},"language":_vm.language,"selectedValue":_vm.info.data[d.name]},on:{"get":function($event){return _vm.setChekbox('data', $event, d.name)}}}):(d.type === 'Image' || d.type === 'File')?_c('photo-loader',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"options":{
					label: d.label,
					minContent: '',
					onlyOne: true,
					required: d.validation ? d.validation.required : false,
					onlyOne: d.kind !== 'Array',
					type: d.type,
				},"images":_vm.info.data[d.name]},on:{"photoId":function($event){return _vm.setImgToObject(d.name, $event)}}}):(d.type === 'SubBlock')?_c('multiplie-block',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"data":d,"language":_vm.language,"info":_vm.info},on:{"setBlocks":function($event){return _vm.emitConstructObject(d.name, $event)},"addBlock":function($event){return _vm.addBlock(d, d.name)},"deleteBlock":function($event){return _vm.deleteBlock($event, d.name)}}}):(d.type === 'ColorPicker')?_c('color-picker',{key:d.type + k,attrs:{"value":_vm.whichVal('data', null, d.name),"options":{
					label: d.label,
				}},on:{"get":function($event){return _vm.onChange(d.name, $event)}}}):(d.kind === 'Array' && d.type === 'Text')?_c('tag-container',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"value":_vm.info.data[d.name],"options":{
					label: d.label,
					required: d.validation ? d.validation.required : false,
				}},on:{"get":function($event){return _vm.emitConstructObject(d.name, $event)}}}):_c('input-container',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"value":_vm.whichVal('data', null, d.name),"options":{
					type: d.type,
					label: d.label,
					required: d.validation ? d.validation.required : false,
					kind: d.kind,
				}},on:{"get":function($event){return _vm.onChange(d.name, $event)}}})]})],2),(_vm.checkButtons().all)?_c('div',{staticClass:"control-btns"},[(_vm.checkButtons().addBtn)?_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$emit('addBlock')}}},[_vm._v(" Добавить ")]):_vm._e(),(_vm.checkButtons().deleteBtn)?_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.$emit('deleteBlock')}}},[_vm._v(" Удалить ")]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }