<template>
	<section>
		<div class="top-block">
			<h1>Изменить контакты</h1>
			<lang-selector @get="(e) => (language = e)"></lang-selector>
		</div>
		<div class="mass-container">
			<input-container
				ref="description"
				:options="{
					label: 'Описание',
					full: true,
					size: 3,
				}"
				:value="whichVal('description')"
				@get="whichVal('description', $event)"
			/>
			<input-container
				ref="adress"
				:options="{
					label: 'Адрес',
					size: 3,
				}"
				:value="whichVal('address')"
				@get="whichVal('address', $event)"
			/>
			<input-container
				ref="email"
				:options="{
					label: 'Email',
					size: 2,
				}"
				:value="contactData.email"
				@get="contactData.email = $event"
			/>
			<input-container
				ref="instagram"
				:options="{
					label: 'Instagram',
				}"
				:value="contactData.instagram"
				@get="contactData.instagram = $event"
			/>
			<input-container
				ref="ogrn"
				:options="{
					label: 'ОГРН',
					size: 1,
				}"
				:value="contactData.ogrn"
				@get="contactData.ogrn = $event"
			/>
			<input-container
				ref="inn"
				:options="{
					label: 'ИНН',
					size: 1,
				}"
				:value="contactData.inn"
				@get="contactData.inn = $event"
			/>
			<input-container
				ref="kpp"
				:options="{
					label: 'КПП',
					size: 1,
				}"
				:value="contactData.kpp"
				@get="contactData.kpp = $event"
			/>
			<div class="blocks">
				<ContentBlock
					ref="blocks"
					v-for="(b, k) in contactData.blocks"
					:key="b.id + k"
					:data="dataBlocks[getCurrentScheme(b.id)]"
					:language="language"
					:info="b"
					:outside="true"
					@setData="setBlocks($event, k)"
				/>
			</div>
		</div>
		<button @click="saveData" class="add">
			Обновить данные
		</button>
	</section>
</template>

<script>
import InputContainer from "./components/InputContainer.vue";
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";
import LangSelector from "./components/LangSelector.vue";
import { http } from "../components/AuthService/httpService";
export default {
	mixins: [inputHandlerMixin],
	components: { InputContainer, LangSelector },
	data() {
		return {
			objName: "contactData",
			language: "ru-RU",
			contactData: {
				description: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				address: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				email: "",
				phone: "",
				instagram: "",
				ogrn: "",
				inn: "",
				kpp: "",
				blocks: [],
			},
			dataBlocks: [],
		};
	},
	methods: {
		collectBlocks() {
			http.get("/api/blocks").then((response) => {
				if (response.status < 400) {
					this.dataBlocks = response.data.blocks.filter(
						(e) =>
							e.name === "map" ||
							e.name === "pressKit" ||
							e.name === "contacts-emails"
					);
					for (let b of this.dataBlocks) {
						this.contactData.blocks.push({
							sortOrder: 0,
							data: this.add(b.fields),
							id: b.id,
						});
					}
					this.getContactInfo();
				}
			});
		},
		getCurrentScheme(id) {
			return this.dataBlocks.findIndex((e) => e.id === id);
		},
		setBlocks(data, k) {
			this.contactData.blocks.splice(k, 1, data);
		},
		getContactInfo() {
			http.get("/api/contacts").then((response) => {
				if (response.status < 400) {
					this.contactData = {
						...response.data,
						blocks:
							response.data.blocks.length > 0
								? response.data.blocks
								: this.contactData.blocks,
					};
				}
			});
		},
		saveData() {
			this.loading = true;
			const haveErrors = (txt = null) => {
				let ch = false;
				for (let b in this.$refs) {
					if (this.$refs[b].length !== undefined) {
						this.$refs[b].forEach((e) => {
							if (ch === false) ch = e.checkReq(txt);
							else e.checkReq(txt);
						});
					} else {
						if (ch === false) {
							ch = this.$refs[b].checkReq(txt);
						} else {
							this.$refs[b].checkReq(txt);
						}
					}
				}
				return ch;
			};
			const recursiveImageId = (fields, data) => {
				const newData = { ...data };
				for (let f of fields) {
					if (
						(f.type === "Image" || f.type === "File") &&
						newData[f.name] !== null
					) {
						newData[f.name] =
							f.kind === "Array"
								? newData[f.name].map((i) => i.id)
								: newData[f.name].id;
					} else if (f.type === "SubBlock") {
						if (f.kind === "Array") {
							newData[f.name] = newData[f.name].map((e) =>
								recursiveImageId(f.fields, e)
							);
						} else {
							newData[f.name] = recursiveImageId(
								f.fields,
								newData[f.name]
							);
						}
					}
				}
				return newData;
			};
			const data = {
				...this.contactData,
				blocks: [],
			};
			for (let b of this.dataBlocks) {
				const info = this.contactData.blocks[
					this.contactData.blocks.findIndex((e) => e.id === b.id)
				];
				data.blocks.push({
					...info,
					data: recursiveImageId(b.fields, info.data),
				});
			}
			if (!haveErrors()) {
				http.put("/api/contacts", data).then((response) => {
					if (response.status < 400) {
						this.getContactInfo();
					} else if (response.status < 410) {
						console.log(response.data);
						// haveErrors(response.data);
					}
					this.loading = false;
				});
			} else
				document.dispatchEvent(
					new CustomEvent("loadPosition", {
						detail: {
							message: "Вы не заполнили требуемые данные",
							type: "error",
						},
					})
				);
		},
		addDataBlock(blocks) {
			for (let b of blocks) {
				const ids = this.allBlocks.map((e) => e.id);
				this.dataBlocks.push(this.allBlocks[ids.indexOf(b.id)]);
				this.dataInfo.push(b);
			}
		},
	},
	mounted() {
		this.collectBlocks();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

.top-block {
	display: flex;
	align-items: center;
	margin: 0 0 ptr(70px);
}

h1 {
	display: flex;
	font-size: ptr(36px);
	margin: 0;
	font-weight: 600;
	color: $h-color;
}

article {
	display: grid;
	gap: ptr(20px) 0;
	max-width: ptr(700px);
}

.mass-container {
	width: ptr(800px);
	display: flex;
	flex-wrap: wrap;
	gap: ptr(30px) ptr(20px);
}

.control-btns {
	display: flex;
	align-items: center;
}
.blocks {
	display: grid;
	margin: ptr(20px) 0 ptr(100px);
	gap: ptr(30px);
}
</style>
