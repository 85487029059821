<template>
	<section>
		<div class="top-block">
			<h1>{{ id ? "Изменить новость" : "Добавить новость" }}</h1>
			<lang-selector @get="(e) => (language = e)"></lang-selector>
		</div>
		<article>
			<input-container
				ref="title"
				:options="{
					label: 'Название',
				}"
				:value="whichVal('title')"
				@get="whichVal('title', $event)"
			/>
			<input-container
				ref="date"
				:value="news.date"
				:options="{
					label: 'Дата',
					type: 'Date',
				}"
				@get="news.date = $event"
			/>
			<photo-loader
				:options="{
					label: 'Изображение',
					onlyOne: true,
				}"
				:images="news.image"
				@photoId="news.image = $event.id"
			/>
			<input-container
				ref="content"
				label="Описание"
				:value="whichVal('content')"
				:options="{
					label: 'Описание',
					type: 'Textarea',
				}"
				@get="whichVal('content', $event)"
			/>
			<custom-selector
				:options="{
					label: 'Проект',
					inValue: false,
					data: allProjects,
				}"
				:selectedValue="news.projectId"
				@get="news.projectId = $event"
			/>
		</article>
		<button @click="saveData(id ? 'put' : 'post')" class="add">
			{{ id ? "Изменить" : "Добавить" }}
		</button>
	</section>
</template>

<script>
import LangSelector from "./components/LangSelector.vue";
import { http } from "../components/AuthService/httpService";
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";
import InputContainer from "./components/InputContainer.vue";
import CustomSelector from "./components/CustomSelector.vue";
import PhotoLoader from "./components/PhotoLoader.vue";
export default {
	mixins: [inputHandlerMixin],
	components: {
		LangSelector,
		InputContainer,
		CustomSelector,
		PhotoLoader,
	},
	data() {
		return {
			id: "",
			language: "ru-RU",
			loading: false,
			objName: "news",
			news: {
				title: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				content: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				image: null,
				date: "",
				projectId: null,
			},
			allProjects: [],
		};
	},
	methods: {
		saveData(type) {
			this.loading = true;
			const haveErrors = (txt) => {
				let ch = false;
				for (let b in this.$refs) {
					if (ch === false) {
						ch = this.$refs[b].checkReq(txt);
					} else {
						this.$refs[b].checkReq(txt);
					}
				}
				return ch;
			};
			const news = {
				...this.news,
				date: this.dataParse(this.news.date, true),
				projectId: this.news.projectId.id,
			};
			if (!haveErrors())
				http[type](
					"/api/timelife" + (type === "put" ? "/" + this.id : ""),
					news
				).then(() => {
					this.loading = false;
				});
			else
				document.dispatchEvent(
					new CustomEvent("loadPosition", {
						detail: {
							message: "Вы не заполнили требуемые данные",
							type: "error",
						},
					})
				);
		},
		getNews() {
			http.get("/api/timelife/" + this.id).then((response) => {
				this.news = {
					...response.data,
					date: this.dataParse(response.data.date),
					projectId: response.data.project,
				};
			});
		},
		getProjects() {
			http.get("/api/projects").then((response) => {
				this.allProjects = response.data.projects;
			});
		},
	},
	mounted() {
		if (this.$route.params.id) {
			this.id = this.$route.params.id;
			this.getNews();
		}
		this.getProjects();
	},
};
</script>
<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
	display: grid;
	gap: ptr(70px);
}

.top-block {
	display: flex;
	align-items: center;
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}
article {
	display: flex;
	flex-wrap: wrap;
	max-width: ptr(700px);
	gap: ptr(30px) ptr(20px);
	h2 {
		font-size: ptr(30px);
		font-weight: 500;
		margin: 0;
		color: $h-color;
	}
}
.control-btns {
	display: flex;
	align-items: center;
}
</style>
