<template>
	<div
		class="input-container"
		:class="{ full: localedOptions.full || localedOptions.size === 3 }"
	>
		<div class="label-info">
			{{ localedOptions.label }}
			<span v-if="localedOptions.required"
				>*
				<div v-if="errorText">{{ errorText }}</div></span
			>
		</div>
		<textarea
			v-if="localedOptions.textarea"
			:value="maskedInput"
			@input="setInput"
			@focus="error = false"
			:class="`size-${localedOptions.size} ${error ? 'error' : ''}`"
			:placeholder="localedOptions.placeholder"
		></textarea>
		<input
			v-else
			:value="maskedInput"
			@focus="error = false"
			@input="setInput"
			:class="`size-${localedOptions.size} ${error ? 'error' : ''}`"
			type="text"
			:placeholder="localedOptions.placeholder"
		/>
	</div>
</template>

<script>
export default {
	props: {
		options: {
			default: () => ({}),
		},
		value: {
			default: "",
		},
	},
	data() {
		return {
			errorText: "",
			inp: "",
			error: false,
			maskedInput: "",
			localedOptions: {
				type: "Text",
				label: "",
				mask: "",
				required: true,
				placeholder: "",
				full: false,
				size: 0,
				textarea: false,
			},
		};
	},
	methods: {
		checkReq(errComponent) {
			if (
				!this.localedOptions.required ||
				(!errComponent && this.maskedInput !== "")
			)
				return false;
			this.error = true;
			if (errComponent) {
				this.errorText = errComponent.txt;
			} else {
				this.errorText = "Вы не указали данные";
			}
			return true;
		},
		setInput(e) {
			if (
				this.localedOptions.mask.length > 0 &&
				e.target.value.length > this.localedOptions.mask.length
			) {
				e.target.value = this.maskInput(this.value);
			} else {
				this.$emit("get", e.target.value);
			}
		},
		maskInput(val) {
			let res = "";
			if (this.localedOptions.mask.length > 0) {
				for (let i = 0; i < val.length; i++) {
					if (this.localedOptions.mask[i] === val[i]) {
						res += this.localedOptions.mask[i];
					} else if (this.localedOptions.mask[i] === "#") {
						res += val[i];
					} else {
						res += this.localedOptions.mask[i] + val[i];
					}
				}
				return res;
			}
			return val;
		},
	},
	watch: {
		error(newVal) {
			if (!newVal) {
				this.errorText = "";
			}
		},
		value(newVal) {
			this.maskedInput = this.maskInput(newVal);
		},
	},
	mounted() {
		new Promise((resolve) => {
			Object.assign(this.localedOptions, this.options);
			if (this.localedOptions.type === "Textarea") {
				this.localedOptions.full = true;
				this.localedOptions.textarea = true;
			}
			if (this.localedOptions.type === "Date") {
				this.localedOptions.mask = "##.##.####";
			}
			if (this.localedOptions.type === "Time") {
				this.localedOptions.mask = "##:##";
			}
			if (this.localedOptions.type === "DateTime") {
				this.localedOptions.mask = "##.##.#### ##:##";
			}
			if (this.localedOptions.type === "Phone") {
				this.localedOptions.mask = "#(###)###-##-##";
			}
			if (this.localedOptions.type === "Phone") {
				this.localedOptions.mask = "#(###)###-##-##";
			}
			resolve();
		}).then(() => {
			this.maskedInput = this.maskInput(this.value);
		});
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

.input-container {
	display: grid;
	gap: ptr(10px);
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	span {
		margin-left: ptr(5px);
		color: $red;
		position: relative;
	}
	div {
		position: absolute;
		left: ptr(20px);
		top: 0;
		width: ptr(230px);
		background: white;
		border: 1px solid $red;
		border-radius: ptr(5px);
		padding: ptr(10px);
		pointer-events: none;
	}
}
input,
textarea {
	border-radius: $brd;
	background: white;
	border: 1px solid $border-color;
	height: ptr(45px);
	padding: 0 0 0 ptr(20px);
	transition: $trs;
	font-size: ptr(16px);
	font-weight: 500;
	font-family: $main-font;
	box-sizing: border-box;
	&::placeholder {
		color: $subtext-color;
	}
	&:focus {
		outline: none;
		border: 1px solid $active-color;
	}
}
input{
	width: ptr(340px);
}
textarea {
	height: ptr(350px);
	resize: none;
	padding: ptr(20px) 0 0 ptr(20px);
	&.size-2 {
		height: ptr(100px);
	}
}
.error {
	border: 1px solid $red;
}
.size-1 {
	width: ptr(220px);
}
.size-2 {
	width: ptr(340px);
}
.size-3 {
	width: ptr(700px);
}
.full {
	width: 100%;
	grid-column: span 2;
}
</style>
