<template>
	<section>
		<div class="top-block">
			<h1>Изменить данные на страницах</h1>
			<lang-selector @get="(e) => (language = e)"></lang-selector>
		</div>
		<h2>Главная</h2>
		<photo-loader
			ref="mainVideo"
			:options="{
				label: 'Видео',
				onlyOne: true,
			}"
			:images="mainVideo"
			@photoId="mainVideo = $event"
		/>
		<h2 class="stck">About</h2>
		<div class="blocks">
			<ContentBlock
				ref="blocks"
				v-for="(b, k) in aboutBlocks"
				:key="b.id + k"
				:data="dataBlocks[getCurrentScheme(b.id)]"
				:language="language"
				:info="b"
				:outside="true"
				@setData="setBlocks($event, k)"
			/>
		</div>
		<button @click="saveAbout" class="add">
			Обновить данные
		</button>
	</section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
import PhotoLoader from "./components/PhotoLoader.vue";
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";
import LangSelector from "./components/LangSelector.vue";
export default {
	components: {
		PhotoLoader,
		LangSelector,
	},
	mixins: [inputHandlerMixin],
	data() {
		return {
			language: "ru-RU",
			aboutBlocks: [],
			dataBlocks: [],
			mainVideo: null,
		};
	},
	methods: {
		collectBlocks() {
			http.get("/api/blocks").then((response) => {
				if (response.status < 400) {
					this.dataBlocks = response.data.blocks.filter(
						(e) =>
							e.name === "subheader-text-w-sticker" ||
							e.name === "numbers" ||
							e.name === "videoBlock" ||
							e.name === "partners"
					);
					for (let b of this.dataBlocks) {
						this.aboutBlocks.push({
							sortOrder: 0,
							data: this.add(b.fields),
							id: b.id,
						});
					}
					this.getAllInfo();
				}
			});
		},
		getCurrentScheme(id) {
			return this.dataBlocks.findIndex((e) => e.id === id);
		},
		setBlocks(data, k) {
			this.aboutBlocks.splice(k, 1, data);
		},
		getAllInfo() {
			http.get("/api/pages/about").then((response) => {
				if (response.status < 400) {
					this.aboutBlocks =
						response.data.blocks.length > 0
							? response.data.blocks
							: this.aboutBlocks;
				}
			});
			http.get("/api/pages/main").then((response) => {
				if (response.status < 400) {
					this.mainVideo = response.data.video;
				}
			});
		},
		saveAbout() {
			this.loading = true;
			const recursiveImageId = (fields, data) => {
				const newData = { ...data };
				for (let f of fields) {
					if (
						(f.type === "Image" || f.type === "File") &&
						newData[f.name] !== null
					) {
						newData[f.name] =
							f.kind === "Array"
								? newData[f.name].map((i) => i.id)
								: newData[f.name].id;
					} else if (f.type === "SubBlock") {
						if (f.kind === "Array") {
							newData[f.name] = newData[f.name].map((e) =>
								recursiveImageId(f.fields, e)
							);
						} else {
							newData[f.name] = recursiveImageId(
								f.fields,
								newData[f.name]
							);
						}
					}
				}
				return newData;
			};
			const data = {
				blocks: [],
			};
			for (let b of this.dataBlocks) {
				const info = this.aboutBlocks[
					this.aboutBlocks.findIndex((e) => e.id === b.id)
				];
				data.blocks.push({
					...info,
					data: recursiveImageId(b.fields, info.data),
				});
			}
			if (!this.haveErrors()) {
				http.put("/api/pages/about", data).then((response) => {
					if (response.status < 400) {
						this.saveMain();
					} else {
						this.loading = false;
					}
				});
			} else
				document.dispatchEvent(
					new CustomEvent("loadPosition", {
						detail: {
							message: "Вы не заполнили требуемые данные",
							type: "error",
						},
					})
				);
		},
		haveErrors(txt = null) {
			let ch = false;
			for (let b in this.$refs) {
				if (this.$refs[b].length !== undefined) {
					this.$refs[b].forEach((e) => {
						if (ch === false) ch = e.checkReq(txt);
						else e.checkReq(txt);
					});
				} else {
					if (ch === false) {
						ch = this.$refs[b].checkReq(txt);
					} else {
						this.$refs[b].checkReq(txt);
					}
				}
			}
			return ch;
		},
		saveMain() {
			if (!this.haveErrors()) {
				http.put("/api/pages/main", { video: this.mainVideo.id }).then(
					(response) => {
						if (response.status < 400) {
							this.getAllInfo();
						}
						this.loading = false;
					}
				);
			} else
				document.dispatchEvent(
					new CustomEvent("loadPosition", {
						detail: {
							message: "Вы не заполнили требуемые данные",
							type: "error",
						},
					})
				);
		},
		addDataBlock(blocks) {
			for (let b of blocks) {
				const ids = this.allBlocks.map((e) => e.id);
				this.dataBlocks.push(this.allBlocks[ids.indexOf(b.id)]);
				this.dataInfo.push(b);
			}
		},
	},
	mounted() {
		this.collectBlocks();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
	display: flex;
	flex-direction: column;
}

.top-block {
	display: flex;
	align-items: center;
	margin: 0 0 ptr(20px);
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}

h2 {
	margin: 0 0 ptr(20px);
	font-size: ptr(36px);
	font-weight: 500;
	color: $h-color;
}

.stck {
	margin-top: ptr(60px);
	padding: ptr(20px) 0;
	position: sticky;
	top: 0;
	z-index: 10;
	background: $back-color;
}

.add {
	margin-top: ptr(60px);
}

.static-content {
	display: flex;
	flex-wrap: wrap;
	max-width: ptr(700px);
	gap: ptr(30px) ptr(20px);
}
.mass-container {
	display: flex;
	flex-wrap: wrap;
	gap: ptr(30px) ptr(20px);
}
.control-btns {
	display: flex;
	align-items: center;
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	span {
		margin-left: ptr(5px);
		color: $red;
	}
}
.active {
	box-shadow: 0 ptr(4px) ptr(7px) rgba(127, 170, 211, 0.3);
}
.blocks {
	margin: 0;
	h2 {
		margin: 0;
	}
	display: grid;
	gap: ptr(100px);
}
</style>
