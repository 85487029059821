<template>
	<div class="block-selector" :class="`size-${options.size}`">
		<div class="label-info">
			{{ options.label }}
			<span v-if="options.requared"
				>*
				<div v-if="errorText">{{ errorText }}</div></span
			>
		</div>
		<div
			class="selector-container"
			tabindex="0"
			@click="
				openCards = true;
				errorText = '';
			"
			@blur="openCards = false"
		>
			<div class="selected-value">
				<span v-if="currentValues.value !== null">
					{{
						options.inValue
							? whichVal("value")
							: currentValues.value.name
					}}
				</span>
				<span v-else>
					Выберете значение
				</span>
				<img src="/static/icons/arrow.svg" alt="" />
			</div>
			<div v-if="openCards" class="options-container">
				<div
					v-for="(e, k) in options.data"
					:key="k"
					@click="select(e)"
					class="opt"
				>
					{{
						options.inValue
							? whichVal("all", null, false, k)
							: e.name
					}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";

export default {
	mixins: [inputHandlerMixin],
	props: {
		options: {
			default: {
				data: [],
				label: "",
				size: 2,
				inValue: true,
				required: false,
			},
		},
		language: {
			default: "ru-RU",
		},
		selectedValue: {
			default: null,
		},
	},
	data() {
		return {
			openCards: false,
			objName: "currentValues",
			errorText: "",
			error: false,
			currentValues: {
				all: [],
				value: null,
			},
		};
	},
	methods: {
		select(e) {
			this.currentValues.value = e;
			this.$emit("get", e);
		},
		checkReq(errComponent) {
			if (!this.options.required || !errComponent) return false;
			this.error = true;
			if (errComponent) {
				this.errorText = errComponent.txt;
			} else {
				this.errorText = "Вы не указали данные";
			}
			return true;
		},
	},
	watch: {
		selectedValue() {
			this.currentValues.all = this.options.data;
			if (this.selectedValue !== null) {
				this.select(this.selectedValue);
			}
		},
	},
	beforeMount() {
		this.currentValues.all = this.options.data;
		if (this.selectedValue !== null) {
			this.select(this.selectedValue);
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";
.block-selector {
	display: grid;
	gap: ptr(10px);
	width: 100%;
	position: relative;
}
.selected-value {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	img {
		position: absolute;
		width: ptr(15px);
		right: ptr(15px);
		opacity: 0.5;
	}
}
.options-container {
	position: absolute;
	z-index: 2;
	top: 100%;
	left: 0;
	width: calc(100% - 2rem / 16);
	border-radius: 0 0 $brd $brd;
	background: white;
	border-top: 1px solid transparent;
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
}
.selector-container {
	border: 1px solid $border-color;
	border-radius: $brd;
	width: 100%;
	box-sizing: border-box;
	height: ptr(45px);
	padding: 0 ptr(10px);
	display: flex;
	align-items: center;
	transition: $trs;
	background: white;
	&:focus-within {
		border: 1px solid $active-color;
	}
	input {
		background: none;
		border: none;
		height: 100%;
		width: 100%;
		font-size: ptr(14px);
		font-weight: 500;
		font-family: $main-font;
		color: black;
		&:focus {
			outline: none;
		}
	}
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	span {
		margin-left: ptr(5px);
		color: $red;
		position: relative;
	}
	div {
		position: absolute;
		left: ptr(20px);
		top: 0;
		width: ptr(230px);
		background: white;
		border: 1px solid $red;
		border-radius: ptr(5px);
		padding: ptr(10px);
		pointer-events: none;
	}
}

.opt {
	padding: ptr(10px) ptr(20px);
	font-weight: 500;
	font-size: ptr(16px);
	cursor: pointer;
	transition: $trs;
	&:hover {
		background: $active-color;
		color: white;
	}
}

.size-1 {
	width: ptr(220px);
}
.size-2 {
	width: ptr(340px);
}
.size-3 {
	width: ptr(700px);
}
</style>
