<template>
	<section>
		<div class="static-content">
			<div class="top-block">
				<h1>{{ id ? "Изменить проект" : "Добавить проект" }}</h1>
				<lang-selector @get="(e) => (language = e)"></lang-selector>
			</div>
			<input-container
				ref="name"
				:options="{
					label: 'Название',
				}"
				:value="whichVal('name')"
				@get="whichVal('name', $event)"
			/>
			<custom-selector
				:options="{ label: 'Группа', size: 2, data: allGroups }"
				@get="projectData.groupId = $event.id"
			/>
			<input-container
				ref="description"
				:value="whichVal('description')"
				:options="{
					label: 'Описание',
					type: 'Textarea',
				}"
				@get="whichVal('description', $event)"
			/>
			<input-container
				ref="type"
				label="Тип"
				:value="whichVal('type')"
				:options="{
					label: 'Тип',
				}"
				@get="whichVal('type', $event)"
			/>
			<input-container
				ref="email"
				:value="projectData.email"
				:options="{
					label: 'Email',
				}"
				@get="projectData.email = $event"
			/>
			<input-container
				ref="facebook"
				:value="projectData.socials.facebook"
				:options="{
					label: 'Facebook',
				}"
				@get="projectData.socials.facebook = $event"
			/>
			<input-container
				ref="email"
				:value="projectData.socials.instagram"
				:options="{
					label: 'Instagram',
				}"
				@get="projectData.socials.instagram = $event"
			/>
			<input-container
				ref="email"
				:value="projectData.socials.vk"
				:options="{
					label: 'Vk',
				}"
				@get="projectData.socials.vk = $event"
			/>
			<input-container
				ref="email"
				:value="projectData.socials.telegram"
				:options="{
					label: 'Telegram',
				}"
				@get="projectData.socials.telegram = $event"
			/>
			<input-container
				ref="url"
				:value="projectData.url"
				:options="{
					label: 'Ссылка',
				}"
				@get="projectData.url = $event"
			/>
			<input-container
				ref="siteUrl"
				:value="projectData.siteUrl"
				:options="{
					label: 'Ссылка на проект',
				}"
				@get="projectData.siteUrl = $event"
			/>
			<photo-loader
				ref="logo"
				:options="{
					label: 'Логотип',
					onlyOne: true,
				}"
				:images="projectData.logo"
				@photoId="projectData.logo = $event"
			/>
			<photo-loader
				ref="banner"
				:options="{
					label: 'Баннер',
					onlyOne: true,
					required: true,
					type: 'File',
				}"
				:images="projectData.banner"
				@photoId="projectData.banner = $event"
			/>
			<custom-checkbox
				label="В процессе"
				:start="projectData.inProgress"
				@get="setChekbox('inProgress', $event)"
			/>
		</div>
		<button @click="saveData(id ? 'put' : 'post')" class="top-fx add">
			{{ id ? "Обновить данные" : "Добавить" }}
		</button>
		<div v-if="dataBlocks.length > 0" class="blocks-content">
			<p>Блоки:</p>
			<ul>
				<li
					v-for="(d, k) in sideBlocks"
					:key="k"
					@click="goToBlock(d.id)"
				>
					{{ k + 1 }}. {{ d.label }}
				</li>
			</ul>
		</div>
		<ContentBlock
			ref="blocks"
			v-for="(b, k) in dataInfo"
			:key="b.id + k"
			:id="`block-number-${k}`"
			:data="dataBlocks[getCurrentScheme(b.id)]"
			:language="language"
			:info="b"
			@sort="sortBlocks"
			@setData="setBlocks($event, k)"
			@deleteBlock="deleteBlock(k)"
		/>
		<article>
			<block-selector :data="allBlocks" @add="addBlock" />
		</article>
		<button @click="saveData(id ? 'put' : 'post')" class="add">
			{{ id ? "Обновить данные" : "Добавить" }}
		</button>
	</section>
</template>

<script>
import LangSelector from "./components/LangSelector.vue";
import { http } from "../components/AuthService/httpService";
import BlockSelector from "./components/BlockSelector.vue";
import ContentBlock from "./components/ContentBlock.vue";
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";
import InputContainer from "./components/InputContainer.vue";
import CustomCheckbox from "./components/CustomCheckbox.vue";
import PhotoLoader from "./components/PhotoLoader.vue";
import Vue from "vue";
import CustomSelector from "./components/CustomSelector.vue";

Vue.component("ContentBlock", ContentBlock);

export default {
	mixins: [inputHandlerMixin],
	components: {
		LangSelector,
		BlockSelector,
		ContentBlock,
		InputContainer,
		CustomCheckbox,
		PhotoLoader,
		CustomSelector,
	},
	data() {
		return {
			id: "",
			language: "ru-RU",
			loading: false,
			allBlocks: [],
			dataInfo: [],
			dataBlocks: [],
			objName: "projectData",
			allGroups: [],
			projectData: {
				name: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				description: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				type: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				socials: {
					facebook: "",
					instagram: "",
					telegram: "",
					vk: "",
				},
				logo: null,
				banner: null,
				email: "email@email.ru",
				url: "url",
				siteUrl: "test",
				inProgress: false,
				fullScreenBanner: false,
			},
		};
	},
	methods: {
		sortBlocks(lastPos, nextPos) {
			const sortedMass = [...this.dataInfo];
			if (sortedMass.length < 2) {
				return;
			}
			const moveElemnt = sortedMass.splice(lastPos, 1)[0];
			moveElemnt.sortOrder = nextPos;
			if (lastPos < nextPos) {
				for (let i = lastPos; i < nextPos; i++) {
					sortedMass[i].sortOrder = sortedMass[i].sortOrder--;
				}
			} else {
				for (let i = lastPos - 1; i >= nextPos; i--) {
					sortedMass[i].sortOrder = ++sortedMass[i].sortOrder;
				}
			}
			sortedMass.push(moveElemnt);
			this.dataInfo = sortedMass
				.sort((a, b) => {
					return a.sortOrder - b.sortOrder;
				})
				.map((e, k) => ({ ...e, sortOrder: k }));
		},
		setBlocks(data, k) {
			this.dataInfo.splice(k, 1, data);
		},
		deleteBlock(k) {
			this.dataBlocks.splice(k, 1);
			this.dataInfo.splice(k, 1);
		},
		getCurrentScheme(id) {
			return this.dataBlocks.findIndex((e) => id === e.id);
		},
		addBlock(block) {
			this.dataBlocks.push(block);
			this.dataInfo.push({
				sortOrder: this.dataInfo.length,
				data: this.add(block.fields),
				id: block.id,
			});
		},
		goToBlock(id) {
			this.$el
				.querySelector(
					`#block-number-${this.dataInfo.findIndex(
						(e) => id === e.id
					)}`
				)
				.scrollIntoView();
		},
		setSticker(del = false) {
			if (!del) {
				this.eventInfo.sticker = null;
			} else {
				this.eventInfo.sticker = {
					message: [
						{
							lang: "ru-RU",
							value: "",
						},
						{
							lang: "en-US",
							value: "",
						},
					],
					color: "",
					offsetTop: 0,
					offsetLeft: 0,
				};
			}
		},
		saveData(type) {
			this.loading = true;
			const haveErrors = (txt = null) => {
				let ch = false;
				for (let b of this.$refs.blocks) {
					if (ch === false) {
						ch = b.checkReq(txt);
					} else {
						b.checkReq(txt);
					}
				}
				return ch;
			};
			const recursiveImageId = (fields, data) => {
				const newData = { ...data };
				for (let f of fields) {
					if (
						(f.type === "Image" || f.type === "File") &&
						newData[f.name] !== null
					) {
						newData[f.name] =
							f.kind === "Array"
								? newData[f.name].map((i) => i.id)
								: newData[f.name].id;
					} else if (f.type === "SubBlock") {
						if (f.kind === "Array") {
							newData[f.name] = newData[f.name].map((e) =>
								recursiveImageId(f.fields, e)
							);
						} else {
							newData[f.name] = recursiveImageId(
								f.fields,
								newData[f.name]
							);
						}
					}
				}
				return newData;
			};
			const projectData = {
				...this.projectData,
				banner: this.projectData.banner
					? this.projectData.banner.id
					: null,
				logo: this.projectData.logo ? this.projectData.logo.id : null,
				blocks: [],
			};
			for (let b of this.dataBlocks) {
				const info = this.dataInfo[
					this.dataInfo.findIndex((e) => e.id === b.id)
				];
				projectData.blocks.push({
					...info,
					data: recursiveImageId(b.fields, info.data),
				});
			}
			if (!haveErrors()) {
				http[type](
					"/api/projects" + (type === "put" ? "/" + this.id : ""),
					projectData
				).then((response) => {
					if (response.status < 400) {
						this.getInfo();
					}
					this.loading = false;
				});
			} else
				document.dispatchEvent(
					new CustomEvent("loadPosition", {
						detail: {
							message: "Вы не заполнили требуемые данные",
							type: "error",
						},
					})
				);
		},
		getInfo() {
			http.get("/api/projects/" + this.id).then((response) => {
				if (response.status < 400) {
					this.dataInfo = [];
					this.dataBlocks = [];
					this.addDataBlock(response.data.blocks);
					this.projectData = response.data;
				}
			});
		},
		addDataBlock(blocks) {
			for (let b of blocks) {
				const ids = this.allBlocks.map((e) => e.id);
				this.dataBlocks.push(this.allBlocks[ids.indexOf(b.id)]);
			}
			this.dataInfo = blocks
				.sort((a, b) => {
					return a.sortOrder - b.sortOrder;
				})
				.map((e, k) => ({ ...e, sortOrder: k }));
		},
		getAllBlocks() {
			http.get("/api/blocks").then((response) => {
				if (response.status < 400) {
					this.allBlocks = response.data.blocks;
					this.getInfo();
				}
			});
		},
		getGroups() {
			http.get("/api/project-groups").then((response) => {
				if (response.status < 400) {
					this.allGroups = response.data.groups;
				}
			});
		},
		setDate() {
			const d = this.dateObj.date.split(".").map((e) => parseInt(e));
			const t = this.dateObj.time.split(":").map((e) => parseInt(e));
			const offset = new Date().getTimezoneOffset() / 60;
			return new Date(d[2], d[1] - 1, d[0], t[0] - offset, t[1]);
		},
	},
	computed: {
		sideBlocks() {
			return this.dataInfo.map(
				(e) => this.dataBlocks[this.getCurrentScheme(e.id)]
			);
		},
	},
	mounted() {
		this.id = this.$route.params.id ? this.$route.params.id : "";
		this.getAllBlocks();
		this.getGroups();
	},
};
</script>
<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
	display: grid;
	gap: ptr(120px) 0;
}

.top-block {
	display: flex;
	align-items: center;
	margin: 0;
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}
article {
	display: flex;
	flex-wrap: wrap;
	max-width: ptr(700px);
	gap: ptr(30px) ptr(20px);
	h2 {
		font-size: ptr(30px);
		font-weight: 500;
		margin: 0;
		color: $h-color;
	}
}
.static-content {
	display: flex;
	flex-wrap: wrap;
	max-width: ptr(700px);
	gap: ptr(30px) ptr(20px);
}
.mass-container {
	display: flex;
	flex-wrap: wrap;
	gap: ptr(30px) ptr(20px);
}
.control-btns {
	display: flex;
	align-items: center;
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	span {
		margin-left: ptr(5px);
		color: $red;
	}
}
.active {
	box-shadow: 0 ptr(4px) ptr(7px) rgba(127, 170, 211, 0.3);
}
.blocks-content {
	position: fixed;
	border: 1px solid $border-color;
	border-radius: ptr(8px);
	background: white;
	padding: ptr(20px) ptr(30px);
	max-width: ptr(200px);
	right: ptr(20px);
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	font-size: ptr(16px);
	p {
		font-weight: 500;
		font-size: ptr(18px);
		margin: 0;
	}
	ul {
		margin: ptr(20px) 0 0;
		display: grid;
		gap: ptr(15px);
	}
	li {
		transition: $trs;
		cursor: pointer;
		&:hover {
			color: $active-color;
		}
	}
}
.top-fx {
	position: fixed;
	top: ptr(50px);
	right: ptr(0px);
}
</style>
