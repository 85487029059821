<template>
	<article :class="{ 'sub-block': subBlock, outside }">
		<div class="block-head-info-container">
			<h2>
				{{ data.label }}
			</h2>
			<order-block
				v-if="(data.kind === 'Array' || !subBlock) && !outside"
				:order="info.sortOrder"
				@changeOrder="sortByOrder"
			/>
		</div>
		<map-component
			v-if="data.name === 'map'"
			:data="info.data"
			@get="
				$emit('setData', {
					data: $event,
					id: info.id,
					sortOrder: info.sortOrder,
				})
			"
		/>
		<div v-else class="mass-container">
			<template v-for="(d, k) in data.fields">
				<custom-checkbox
					v-if="d.type === 'Checkbox'"
					:key="d.type + k"
					:ref="d.name"
					:label="d.label"
					:start="info.data[d.name]"
					@get="setChekbox('data', $event, d.name)"
				/>
				<custom-selector
					v-else-if="d.type === 'Select'"
					:key="d.type + k"
					:ref="d.name"
					:options="{
						inValue: true,
						label: d.label,
						data: d.params.values,
						requared: d.validation.required,
					}"
					:language="language"
					:selectedValue="info.data[d.name]"
					@get="setChekbox('data', $event, d.name)"
				/>
				<photo-loader
					v-else-if="d.type === 'Image' || d.type === 'File'"
					:key="d.type + k"
					:ref="d.name"
					:options="{
						label: d.label,
						minContent: '',
						onlyOne: true,
						required: d.validation ? d.validation.required : false,
						onlyOne: d.kind !== 'Array',
						type: d.type,
					}"
					:images="info.data[d.name]"
					@photoId="setImgToObject(d.name, $event)"
				/>
				<multiplie-block
					v-else-if="d.type === 'SubBlock'"
					:key="d.type + k"
					:ref="d.name"
					:data="d"
					:language="language"
					:info="info"
					@setBlocks="emitConstructObject(d.name, $event)"
					@addBlock="addBlock(d, d.name)"
					@deleteBlock="deleteBlock($event, d.name)"
				/>
				<color-picker
					v-else-if="d.type === 'ColorPicker'"
					:key="d.type + k"
					:value="whichVal('data', null, d.name)"
					:options="{
						label: d.label,
					}"
					@get="onChange(d.name, $event)"
				/>
				<tag-container
					v-else-if="d.kind === 'Array' && d.type === 'Text'"
					:key="d.type + k"
					:ref="d.name"
					:value="info.data[d.name]"
					:options="{
						label: d.label,
						required: d.validation ? d.validation.required : false,
					}"
					@get="emitConstructObject(d.name, $event)"
				/>
				<input-container
					v-else
					:key="d.type + k"
					:ref="d.name"
					:value="whichVal('data', null, d.name)"
					:options="{
						type: d.type,
						label: d.label,
						required: d.validation ? d.validation.required : false,
						kind: d.kind,
					}"
					@get="onChange(d.name, $event)"
				/>
			</template>
		</div>
		<div v-if="checkButtons().all" class="control-btns">
			<button
				v-if="checkButtons().addBtn"
				@click="$emit('addBlock')"
				class="add"
			>
				Добавить
			</button>
			<button
				v-if="checkButtons().deleteBtn"
				@click="$emit('deleteBlock')"
				class="delete"
			>
				Удалить
			</button>
		</div>
	</article>
</template>

<script>
import InputContainer from "./InputContainer.vue";
import inputHandlerMixin from "@/views/mixins/inputHandlerMixin";
import PhotoLoader from "./PhotoLoader.vue";
import OrderBlock from "./OrderBlock.vue";
import CustomCheckbox from "./CustomCheckbox.vue";
import CustomSelector from "./CustomSelector.vue";
import MultiplieBlock from "./MultiplieBlock.vue";
import ColorPicker from "./ColorPicker.vue";
import MapComponent from "./MapComponent.vue";
import TagContainer from "./TagContainer.vue";
export default {
	name: "ContentBlock",
	mixins: [inputHandlerMixin],
	components: {
		InputContainer,
		PhotoLoader,
		OrderBlock,
		CustomCheckbox,
		CustomSelector,
		MultiplieBlock,
		ColorPicker,
		MapComponent,
		TagContainer,
	},
	props: {
		language: {
			default: "ru-RU",
		},
		data: {
			default: () => ({}),
		},
		info: {
			default: () => ({}),
		},
		multiplyLength: {
			default: 0,
		},
		position: {
			default: 0,
		},
		subBlock: {
			default: false,
		},
		outside: {
			default: false,
		},
	},
	data() {
		return {
			objName: "info",
			show: false,
		};
	},
	methods: {
		sortByOrder(newOrder) {
			this.$emit("sort", this.info.sortOrder, newOrder);
		},
		checkReq(errComponent) {
			let chk = false;
			for (let k in this.$refs) {
				chk = this.$refs[k].forEach((e) => e.checkReq(errComponent));
			}
			return chk;
		},
		setImgToObject(name, ids) {
			// const setId = (ids) => {
			// 	if (!ids) {
			// 		return null;
			// 	}
			// 	return ids.length === undefined ? ids.id : ids.map((e) => e.id);
			// };
			// ids = setId(ids);
			this.emitConstructObject(name, ids);
		},
		checkButtons() {
			const array = this.data.kind === "Array";
			const last = this.multiplyLength - 1 === this.position;
			const maxCount = this.data.params ? this.data.params.maxCount : 0;
			const addBtn = array && maxCount > this.multiplyLength && last;
			const deleteBtn =
				(!this.subBlock || (this.subBlock && array)) && !this.outside;
			return {
				addBtn,
				deleteBtn,
				all: addBtn || deleteBtn,
			};
		},
		addBlock(data, name) {
			const obj = this.add(data.fields);
			this.emitConstructObject(name, [...this.info.data[name], obj]);
		},
		deleteBlock(k, name) {
			const filteredArray = [...this.info.data[name]];
			this.info.data[name].length > 1 ? filteredArray.splice(k, 1) : null;
			this.emitConstructObject(name, filteredArray);
		},
		emitConstructObject(name, filteredArray) {
			const newData = {
				...this.info.data,
				[name]:
					typeof filteredArray !== "object" ||
					filteredArray === null ||
					filteredArray.length === undefined
						? filteredArray
						: [...filteredArray],
			};
			const newInfo = {
				...this.info,
				data: newData,
			};
			if (this.subBlock) {
				delete newData.sortOrder;
			}
			this.$emit("setData", this.subBlock ? newData : newInfo);
		},
		setSubBlockInfo(event, name) {
			const filteredArray = [...this.info.data[name]];
			filteredArray.splice(event.key, 1, event.data);
			this.emitConstructObject(name, filteredArray);
		},
		onChange(name, value) {
			const filteredArray = this.info.data[name].filter(
				(e) => e.lang !== this.language
			);
			this.emitConstructObject(name, [
				...filteredArray,
				{
					lang: this.language,
					value,
				},
			]);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

article {
	display: grid;
	gap: ptr(20px) 0;
	max-width: ptr(700px);
	padding-top: ptr(30px);
	position: relative;
	&::before {
		content: "";
		width: ptr(1200px);
		height: ptr(1px);
		position: absolute;
		background: rgb(129, 129, 129);
		top: ptr(0px);
	}
}

.block-head-info-container {
	display: flex;
	position: sticky;
	top: 0;
	z-index: 6;
	padding: ptr(20px) 0;
	width: 100%;
	background: $back-color;
}

h2 {
	font-size: ptr(30px);
	font-weight: 500;
	margin: 0;
	color: $h-color;
}

.outside {
	border: none;
}

.sub-block {
	border-top: 1px solid $border-color;
	&::before {
		content: none;
	}
	.block-head-info-container {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		position: relative;
		z-index: 1;
		padding: 0;
		h2 {
			font-size: ptr(22px);
		}
	}
	.order-conteiner {
		margin: 0 ptr(20px) 0 0;
	}
	&:nth-child(1) {
		padding-top: ptr(0px);
		border-top: unset;
	}
}

.mass-container {
	display: flex;
	flex-wrap: wrap;
	gap: ptr(30px) ptr(20px);
}

.control-btns {
	display: flex;
	align-items: center;
}
</style>
