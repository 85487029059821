<template>
	<main id="app">
        <requests-line/>
        <div class="side-menu">
            <img src="/static/icons/logo.svg" alt="" class="logo">
            <div class="logo-inf">
                <h1>
                    Placebo/25
                </h1>
                <span>admin panel</span>
            </div>
            <nav>
                <ul>
                    <li>
                        <router-link to="/">Проекты</router-link>
                    </li>
                    <li>
                        <router-link to="/TimeLine">Таймлайн</router-link>
                    </li>
                    <li>
                        <router-link to="/pages">Данные на страницах</router-link>
                    </li>
                    <li>
                        <router-link to="/contacts">Контакты</router-link>
                    </li>
                </ul>
            </nav>
            <button @click="logout" class="exit">
                Выйти
            </button>
        </div>
		<router-view />
	</main>
</template>

<script>
    import { authService } from '@/components/AuthService/authService';
    import RequestsLine from '@/components/RequestsLine';
    export default {
        components: { RequestsLine },
        methods:{
            logout(){
                authService.logout()
                .then(()=>{
                    this.$router.replace("/Auth");
                })
            }
        }
    }
</script>

<style lang="scss">
@import "./components/styles/config.scss";
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    padding: ptr(50px) ptr(55px) ptr(100px) ptr(350px);
}
body{
    margin: 0;
    font-family: $main-font;
    background: $back-color;
}
ul{
    padding: 0;
    margin: 0;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
    color: $text-color
}
button{
    font-family: $main-font;
    cursor: pointer;
    border: none;
}
.star-info{
    grid-column: span 2;
    width: 100%;
    font-size: ptr(16px);
    font-weight: 500;
    color: #969FBB;
    span{
        color: $red;
    }
}
.add-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background: $active-color;
    border-radius: $brd;
    color: white;
    font-weight: 500;
    font-size: ptr(14px);
    min-width: ptr(145px);
    height: ptr(46px);
    padding: 0 ptr(40px);
    transition: $trs;
    &:hover{
        background: #008AD8;
    }
    &:active{
        background: #007ABE;
    }
}
.add {
	width: ptr(203px);
	height: ptr(46px);
	border-radius: ptr(10px);
	display: flex;
	align-items: center;
	justify-content: center;
	background: #68c9ff;
	color: white;
	font-weight: 500;
	font-size: ptr(14px);
	margin-right: ptr(20px);
	transition: $trs;
	&:hover {
		background: #3fbcff;
	}
}
.delete {
	height: ptr(46px);
	width: ptr(146px);
	color: white;
	font-weight: 500;
    border-radius: ptr(10px);
	background: $red;
	font-size: ptr(14px);
}
.loading{
    background: rgb(157, 174, 184);
    color: white;
}
</style>

<style lang="scss" scoped>
    @import "./components/styles/config.scss";

    .side-menu{
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: ptr(296px);
        box-sizing: border-box;
        background: white;
        padding: ptr(50px) ptr(30px);
    }
    .logo{
        width: ptr(86px);
        margin-left: ptr(20px);
    }
    .logo-inf{
        margin-left: ptr(20px);
        h1{
            margin: ptr(20px) 0 ptr(5px);
            font-size: ptr(28px);
            font-weight: 700;
            color: $h-color;
        }
        span{
            font-size: ptr(22px);
            font-weight: 500;
            color: $h-color;
        }
    }
    nav{
        margin-top: ptr(120px);
    }
    ul{
        display: grid;
        gap: ptr(5px);
    }
    a{
        display: flex;
        align-items: center;
        padding-left: ptr(20px);
        font-size: ptr(18px);
        font-weight: 500;
        background: rgba(255, 255, 255, 0);
        transition: $trs;
        height: ptr(41px);
        border-radius: $brd;
    }
    .router-link-exact-active{
        color: $active-color;
        background: #F4FBFF;
    }
    .exit{
        background: $active-color;
        width: ptr(196px);
        height: ptr(42px);
        position: absolute;
        bottom: ptr(50px);
        left: ptr(50px);
        border-radius: $brd;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: ptr(14px);
        font-weight: 500;
    }
</style>